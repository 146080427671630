import { FC, ReactNode } from "react";
import classNames from "classnames";

interface DetailsWrapperProps {
  withoutBorder?: boolean;
  withoutdivide?: boolean;
  children: ReactNode;
}

const DetailsWrapper: FC<DetailsWrapperProps> = ({
  children,
  withoutBorder = false,
  withoutdivide = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col",
        !withoutBorder && "border border-foreground/20 rounded-2xl"
      )}
    >
      <div
        className={classNames(
          "px-4",
          !withoutdivide && "divide-y divide-foreground/20"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default DetailsWrapper;
