import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.83002 3.76442C9.92927 3.31778 10.3254 3 10.783 3C11.4075 3 11.8714 3.57831 11.7359 4.18795L8.16976 20.2356C8.07051 20.6822 7.67436 21 7.21682 21C6.5923 21 6.1284 20.4217 6.26388 19.812L9.83002 3.76442Z" />
      <path d="M15.83 3.76442C15.9293 3.31778 16.3254 3 16.783 3C17.4075 3 17.8714 3.57831 17.7359 4.18795L14.1698 20.2356C14.0705 20.6822 13.6744 21 13.2168 21C12.5923 21 12.1284 20.4217 12.2639 19.812L15.83 3.76442Z" />
      <rect x="5" y="7" width="15" height="2" rx="1" />
      <rect x="4" y="14" width="15" height="2" rx="1" />
    </svg>
  );
};

export default Icon;
