import { FC } from "react";
import classNames from "classnames";

import BirthdayIcon from "@components/Icons/BirthdayIcon";
import BlockIcon from "@components/Icons/BlockIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import NumberIcon from "@components/Icons/NumberIcon";

interface ValidationProps {
  validation: { status: string; amountOff: string; isValid: boolean };
}

const Validation: FC<ValidationProps> = ({ validation }) => {
  const { status, isValid, amountOff } = validation;
  const exceeds = status === "usage-exceeded";
  const expired = status === "expired";

  const Icon = isValid
    ? BirthdayIcon
    : exceeds
    ? NumberIcon
    : expired
    ? ClockIcon
    : BlockIcon;

  const description = isValid
    ? "Code applied. You're getting: "
    : exceeds
    ? "This promo code usage limit was exceeded"
    : expired
    ? "This promo code has expired"
    : "This promo code is invalid";

  return (
    <div
      className={classNames(
        "flex items-center gap-2 py-2.5 px-3 rounded-lg text-sm",
        isValid ? "bg-foreground/7 text-foreground" : "bg-error/20 text-error"
      )}
    >
      <Icon />
      {description}
      {isValid && (
        <div className="text-white  bg-blue-300 flex items-center justify-center px-1.5 rounded-md text-xs">
          {amountOff + " off"}
        </div>
      )}
    </div>
  );
};

export default Validation;
