import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { postCouponValidatePromoCodeRedemption } from "api-services/definitions/coupons";
import { api } from "api-services/endpoints";
import { useRouter } from "next/router";

import { Button } from "@components/Button";
import TextField from "@components/Client/TextField";

import Validation from "./Validation";

interface CouponInputProps {
  userId: string;
  packageId?: string;
}

const CouponInput: FC<CouponInputProps> = ({ userId, packageId }) => {
  const { register, handleSubmit } = useForm();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState<any>(null);

  const handleApply = async (data: { promoCode: string }) => {
    const { promoCode } = data;
    setIsLoading(true);

    try {
      const response = await api(postCouponValidatePromoCodeRedemption, {
        body: { promotionCode: promoCode, packageId },
        path: { apiVersion: "v1", userId },
      });
      const coupon = response?.data;
      const queryValue = coupon?.isValid ? coupon.promotionCode : "";
      router.replace(
        {
          query: { ...router.query, promoCode: queryValue },
        },
        undefined,
        { scroll: false }
      );

      setValidation(coupon);
      if (!coupon?.isValid) {
        return "Promo code is not valid.";
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between gap-2 mb-2">
        <TextField
          placeholder="Promo code"
          id="promoCode"
          register={register}
          required
          containerClassName="w-full"
          inputClassName="mt-0 !rounded-lg"
          onInput={(e: any) =>
            (e.target.value = ("" + e.target.value).toUpperCase())
          }
        />
        <Button
          className="rounded-lg text-foreground bg-foreground/7 hover:bg-foreground/20"
          isLoading={isLoading}
          onClick={handleSubmit(handleApply)}
        >
          Apply
        </Button>
      </div>
      {validation && <Validation validation={validation} />}
    </div>
  );
};

export default CouponInput;
