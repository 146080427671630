import { useEffect, useState } from "react";

import usePersistentState from "@hooks/use-persistent-state";

import { FlowContext, FlowProvider } from "./FlowProvider";

export interface FlowStep {
  Component: React.ComponentType;
  getProps?: () => Record<string, unknown>; //TODO: Is it possible to type this function?
}

interface FlowWithStepsProps {
  steps: Array<FlowStep>;
  stateName: string;
}

export const FlowWithSteps: React.FC<FlowWithStepsProps> = ({
  steps,
  stateName,
}) => {
  const { persistentValue: sharedData, persistentSetValue: setSharedData } =
    usePersistentState<any>(stateName, {}, true);

  // This is necessary to avoid hydration errors on reload
  const [loaded, setLoaded] = useState(false);
  useEffect(() => setLoaded(true), []);

  if (!loaded) return null;

  return (
    <FlowProvider
      sharedData={sharedData}
      setSharedData={setSharedData}
      length={steps.length}
    >
      <FlowContext.Consumer>
        {({ currentStep }) => {
          const { Component, getProps } = steps[currentStep];
          const props = getProps && getProps();
          return <Component {...props} />;
        }}
      </FlowContext.Consumer>
    </FlowProvider>
  );
};
