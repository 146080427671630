import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export type StepWithElementProps = {
  stripePublicKey: string;
  clientSecret: string;
  connectedAccountId: string;
};

export const withStripeElement = <T,>(
  Component: React.FC<T & { clientSecret: string }>
): React.FC<T & StepWithElementProps> => {
  return ({ stripePublicKey, clientSecret, connectedAccountId, ...rest }) => {
    const [stripeObject, setStripeObject] = useState(null);
    const options = {
      clientSecret,
    };

    useEffect(() => {
      const getStripeObject = async () => {
        const res = await loadStripe(stripePublicKey, {
          stripeAccount: connectedAccountId,
        });
        if (!stripeObject) {
          setStripeObject(res);
        }
      };
      getStripeObject();
    }, [stripePublicKey]);

    return (
      <Elements stripe={stripeObject} options={options}>
        <Component clientSecret={clientSecret} {...rest} />
      </Elements>
    );
  };
};
