import React, { FC, ReactNode } from "react";
import classNames from "classnames";

interface SectionDetailsPageProps {
  className?: string;
  children: ReactNode;
  title?: string;
  description?: string;
  columnsLayout?: boolean;
  columnChildren?: ReactNode;
}

const SectionDetailsPage: FC<SectionDetailsPageProps> = ({
  children,
  title,
  description,
  className,
  columnsLayout = false,
  columnChildren = undefined,
}) => {
  const renderTitle = title && (
    <h3 className="text-xl leading-6 text-black-ink mb-1">{title}</h3>
  );

  const renderDescription = description && (
    <p className="font-normal text-sm text-grey-500">{description}</p>
  );

  return (
    <div className={classNames("bg-white py-5", className)}>
      {columnsLayout ? (
        <div className="md:grid md:grid-cols-6 md:gap-8">
          <div className="md:col-span-3">
            {renderTitle}
            {renderDescription}
            {columnChildren}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-3">{children}</div>
        </div>
      ) : (
        <>
          <div className="mb-4">
            {renderTitle}
            {renderDescription}
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default SectionDetailsPage;
