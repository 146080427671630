import React from "react";
import classNames from "classnames";

const ErrorField = ({ children, className = "" }) => {
  const containerClassName = classNames(
    "text-sm p-3 rounded-md flex flex-row items-center text-error bg-error/20",
    className
  );
  return (
    <div className={"bg-white my-2  rounded-md"}>
      <div className={containerClassName}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8716 5.54947C12.4893 4.86982 11.5107 4.86982 11.1284 5.54947L3.83825 18.5097C3.46329 19.1763 3.945 20 4.70983 20H19.2901C20.055 20 20.5367 19.1763 20.1617 18.5097L12.8716 5.54947ZM11 10.5C11 10.2239 11.2238 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V14.5C13 14.7761 12.7761 15 12.5 15H11.5C11.2238 15 11 14.7761 11 14.5V10.5ZM11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17Z"
          />
        </svg>
        <p id="email-error" className="ml-2 flex-1">
          {children}
        </p>
      </div>
    </div>
  );
};

export default ErrorField;
