import { FC, MouseEvent, ReactNode } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

interface OptionItemCardProps {
  isSelected: boolean;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  isDisabled: boolean;
  children: ReactNode;
}

export const OptionItemCard: FC<OptionItemCardProps> = ({
  isSelected,
  onClick,
  isDisabled,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-x-4 min-w-0 border rounded-md py-3 px-4 cursor-pointer my-2",
        !isSelected ? "border-grey-900 " : "border-action-800 bg-action-900",
        isDisabled && "border-grey-900 bg-grey-950"
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface OptionItemProps {
  title: string;
  description: string;
  icon: FC<SVGIconProps>;
  selected: boolean;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  rightIcon?: FC<SVGIconProps>;
}

const OptionItem: FC<OptionItemProps> = ({
  title,
  description,
  icon: Icon,
  selected,
  onClick,
  disabled = false,
  rightIcon: RightIcon,
}) => {
  return (
    <OptionItemCard
      isSelected={selected}
      onClick={onClick}
      isDisabled={disabled}
    >
      <div className="shrink-0">
        <Icon />
      </div>
      <div className="flex flex-col text-sm grow">
        <div className="font-medium">{title}</div>
        <div className="text-grey-500">{description}</div>
      </div>
      {RightIcon && (
        <div className="shrink-0 text-grey-500">
          <RightIcon />
        </div>
      )}
    </OptionItemCard>
  );
};

export default OptionItem;
