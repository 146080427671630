import React, { FC } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import RPhoneInput from "react-phone-number-input/react-hook-form";
import classNames from "classnames";
import { get } from "lodash";

import { ErrorMessage } from "@components/ErrorMessage";
import InfoIcon from "@components/Icons/InfoFilledIcon";

import { PhoneInputProps } from "./types";

import styles from "./styles.module.css";

const PhoneInput: FC<PhoneInputProps> = ({
  name,
  control,
  errors,
  errorMessage = "",
  required = false,
  infoText,
  defaultCountry = "US",
  ...rest
}) => {
  const error = get(errors, name);

  const rules = {
    required: { value: required, message: errorMessage },
    validate: (value: string) => {
      if (value && !isValidPhoneNumber(value)) {
        return "Invalid phone number";
      }
    },
  };

  const renderError = error && error.message && (
    <ErrorMessage className="mt-2">{error.message}</ErrorMessage>
  );

  const renderInfo = infoText && (
    <div className="flex items-center mt-2 text-grey-500">
      <InfoIcon className="mr-1" />
      <p className="font-medium text-xs">{infoText}</p>
    </div>
  );

  return (
    <>
      <RPhoneInput
        name={name}
        control={control}
        rules={rules}
        defaultCountry={defaultCountry}
        className={classNames(styles.container, error && styles.error)}
        {...rest}
      />
      {renderError}
      {renderInfo}
    </>
  );
};

export default PhoneInput;
